<template>
  <div class="w-full p-[30px] border border-[#E5E8ED] rounded-[4px] hover:shadow-lg duration-300 ease-in-out">
    <div class="flex gap-[40px] items-center max8:flex-col max8:items-start">
      <div class="flex gap-[20px] items-center">
        <p class="text-[48px] font-[900] text-blue">{{ formatDay(item.created_at) }}</p>
        <div>
          <p class="font-[700] text-[20px] capitalize">{{ formatMonth(item.created_at) }}</p>
          <p class="font-[400] text-[16px] text-[#787A80]">{{ formatTime(item.created_at) }}</p>
        </div>
      </div>
      <div>
        <p class="text-[#1E212C] font-[700] text-[20px] mb-[8px]">{{ formatTitle(item.title) }}</p>
        <p class="break-all">{{ formatDescription(item.description) }}</p>
      </div>
      <router-link
          :to="{name: 'news-page', params:{id:item.slug}}"
          class="flex items-center mt-3 gap-1 py-3 rounded-[4px] justify-center ml-auto p-1 max-w-[300px] w-full border-2 border-blue hover:bg-blue text-white text-[16px] text-blue hover:text-white font-medium duration-300 ease-in-out maxsm:ml-0 maxsm:max-w-full">
        <p>Читать далее</p>
        <img src="img/arrow.svg">
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsListItem",
  props: {
    item: Object
  },
  methods: {
    formatDay(date){
      let full_data = date;
      let full_data_time = new Date(full_data)
      let d = full_data_time.getDate();
      if(d < 10){
        d = '0' + d;
      }
      return d
    },
    formatMonth(date){
      let d = new Date(date);
      let options = {
        month: 'long',
      };
      return (d.toLocaleString("ru", options));
    },
    formatTime(date){
      let options = {hour: 'numeric', minute: 'numeric'}
      let d = new Date(date)
      return d.toLocaleString('ru', options)
    },
    formatTitle(data){
      let text = '';
      if(data.length > 100){
        text = data.slice(0, 99) + '...'
        return text
      } else {
        return data
      }
    },
    formatDescription(data){
      let text = '';
      if(data.length > 100){
        text = data.slice(0, 99) + '...'
        return text
      } else {
        return data
      }
    },
  }
}
</script>

<style scoped>

</style>