<template>
  <div class="app">
    <router-view/>
  </div>
</template>

<script>
import HomeView from '@/views/HomeView.vue'
  export default {
    component: {
      HomeView
    }
  }
</script>

<style >
body, html{
  overflow-x: hidden;
  scroll-behavior: smooth;
}
 

</style>
