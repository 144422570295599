<template>
  <div class="flex maxlg:flex-col maxlg:pt-[80px] maxusm:flex-col md:pl-0 pr-0 my-32 justify-around items-center pb-[100px]"
       id="strategy">
    <div class="relative strategy-column strategy-box p-[40px] w-full bg-white z-[1] after:content-[''] after:block after:absolute after:left-[-30px] after:top-[-40px] after:w-[300px] after:h-[300px] after:bg-[url('dots.png')] after:bg-no-repeat after:bg-[length:200px] after:opacity-[0.5] after:z-[-2]">
        <div class="bg-white">
          <h3 class="text-[32px] font-bold">Наша стратегия</h3>
          <div class="h-[2px] bg-[#86a8ff] rounded-[4px] w-full mt-[15px]"></div>
          <p class="pt-[17px] colors-gray opacity-70">Мы предоставляем своим клиентам широкий спектр услуг, включая
            поставку дизельного топлива на объект, его хранение и транспортировку. Мы понимаем, что каждый клиент имеет
            свои специфические требования, и мы готовы предложить индивидуальные решения, которые соответствуют их
            потребностям.

            Кроме того, мы ценим время наших клиентов и всегда стараемся выполнять заказы в срок и своевременно сообщать о
            любых изменениях в графике поставок.

            Наша команда профессиональных менеджеров и логистов всегда готова ответить на любые вопросы клиентов и
            предложить наилучшие решения для их бизнеса.

            Если вы ищете надежного партнера для поставки дизельного топлива, обращайтесь к нам. Мы готовы предложить
            высококачественное топливо и надежные поставки для вашего бизнеса.</p>
        </div>
    </div>
    <aos-vue
        animation="fade-left"
        :duration="1000"
        :offset="-50"
        :once="true"
    >
    <div class="strategy-column items-center w-full px-4 md:pl-[60px] pt-[110px]">
      <div class="strategy-block strategy-s px-[38px] py-[36px]">
        <p class="text-xl font-bold">Надежный дизель для надежного бизнеса</p>
        <p class="color-gray opacity-70">Мы понимаем, что каждый бизнес имеет свои особенности, и мы готовы предложить
          индивидуальные решения, которые наилучшим образом соответствуют потребностям наших клиентов.</p>
      </div>
      <div class="strategy-block strategy-s px-[38px] py-[36px]">
        <p class="text-xl font-bold">Дизель - сила вашего бизнеса</p>
        <p class="color-gray opacity-70">Мы понимаем, что сильный бизнес требует надежной поддержки. Наша компания
          готова предоставить нашим клиентам высококвалифицированных специалистов и лучшие технологии для обеспечения
          оптимальной работы и хранения топлива.</p>
      </div>
      <div class="strategy-block strategy-s px-[38px] py-[36px]">
        <p class="text-xl font-bold">Дизель - энергия вашего успеха</p>
        <p class="color-gray opacity-70">Мы понимаем, что время - это деньги, и что каждая минута простоя может привести
          к убыткам. Поэтому мы обеспечиваем быстрые и своевременные поставки нашего топлива, а также предоставляем
          услуги складирования и логистической поддержки.</p>
      </div>
    </div>
    </aos-vue>
  </div>
</template>

<script>
import Titles from '@/components/UI/Titles.vue'

export default {
  components: {
    Titles
  }
}
</script>

<style lang="scss" scoped>
.strategy-box {
  box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08), 0px 30px 24px -10px rgba(154, 156, 165, 0.05), 0px 12px 10px -6px rgba(154, 156, 165, 0.04), 0px 4px 4px -4px rgba(30, 33, 44, 0.03);
  border-radius: 4px;
}

.strategy-s {
  box-shadow: 0px 7px 4px rgba(5, 5, 5, 0.01), 0px 3px 3px rgba(5, 5, 5, 0.02), 0px 1px 2px rgba(5, 5, 5, 0.02), 0px 0px 0px rgba(5, 5, 5, 0.02);
  background: #FFFFFF;
  border: 1px solid #F3F2FF;
  border-radius: 20px;
  margin-bottom: 20px;
}
</style>