import { createStore } from 'vuex'
import axios from "@/axios";
import {toast} from "vue3-toastify"

export default createStore({
  state() {
    return {
      news: {},
      news_detail: {},
      products: {},
    }
  },
  getters: {
    getServerDomain(){
      return 'https://admin.fuel-energy.uz/'
    },
    getNews(state){
      return state.news
    },
    getNewsDetail(state){
      return state.news_detail
    },
    getProducts(state){
      return state.products
    }
  },
  mutations: {
    updateNews(state, data){
      state.news = data;
    },
    updateNewsDetail(state, data){
      state.news_detail = data
    },
    updateProducts(state, data){
      state.products = data
    }
  },
  actions: {
    loadNews(context, params){
      axios.get(`news?page=${params.page}&limit=${params.limit}`).then(res => {
        context.commit('updateNews', res.data)
      })
    },
    loadNewsDetail(context, params){
      axios.get(`news/${params.slug}`).then(res => {
        context.commit('updateNewsDetail', res.data.data)
      })
    },
    loadProducts(context, params){
      axios.get(`products?page=${params.page}&limit=${params.limit}`).then(res=> {
        context.commit('updateProducts', res.data.data)
      })
    },
    sendSupport(context, params){
      axios.post("add-order", {order:params}).then(res=> {
        toast.success("Успешно отправлено!", {
          autoClose: 1000,
        });
      }).catch(res=> {
        toast.error("Ошибка!", {
          autoClose: 1000,
        });
      })
    }
  },
})
