<template>
<!--  <div class="lg:flex md:flex md:flex-wrap lg:flex-nowrap lg:pl-20 pr-0 max-w-7xl my-32 justify-between lg:pb-[100px]" id="projects">-->
<!--    <div class="projects-column lg:m-0 md:m-auto sm:m-auto">-->
<!--      <aos-vue class="projects-left"-->
<!--      animation="fade-right"-->
<!--      :duration="700"-->
<!--      :offset="-20"-->
<!--      :once="true"-->
<!--        -->
<!--      >-->
<!--        <h1 class="text-3xl font-bold text-dark usm:flex maxusm:flex lg:block justify-center">Проекты</h1>-->
<!--        <ul class="lg:pt-[37px] sm:pt-[40px] usm:flex usm:justify-center maxusm:flex maxusm:justify-center maxusm:text-center maxusm:flex-wrap lg:flex-col ">-->
<!--          <router-link -->
<!--          v-for="(list, index) in lists" -->
<!--          :key="index" -->
<!--          to="/"-->
<!--          >-->
<!--          <li-->
<!--            class="text-li-gray lg:pb-[18px] maxsm:my-5 usm:px-5 maxusm:px-5 border-dark lg:pl-[16px] text-2xl maxsm:text-base"-->
<!--            :class=" {'usm:border-r-[1px] maxusm:border-r-0 lg:border-none ': index != lists.length-1}"-->
<!--          >-->
<!--          {{ list.name }}-->
<!--          </li>-->
<!--          </router-link>-->
<!--        </ul>-->
<!--      </aos-vue>-->
<!--    </div>-->
<!--    <div class="projects-column flex flex-wrap lg:justify-end md:justify-center lg:mt-20 sm:mt-20 md:auto">-->
<!--    </div>-->
<!--    -->
<!--    <swiper></swiper>-->
<!--    -->
<!--  </div>-->
</template>

<script>
import Swiper from '@/components/UI/Swiper.vue'
  export default {
    components:{
      Swiper
    },
    data() {
      return {
        lists: [
          {name: 'Все'},
          {name: 'Проекты №1'},
          {name: 'Проекты №2'},
          {name: 'Другие'},
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .active-li {
    color: #325ADE;
    border-left: 4px solid #325ADE;
  }
</style>