<template>
    <div class="flex flex-col md:flex max-w-5xl mt-32 relative -z-10">
      <h1 class="text-5xl maxsm:text-[1.5rem] uppercase text-white font-extrabold ">Надежные поставки дизеля для вашего
        бизнеса</h1>
      <div
          class="flex gap-2 relative text-white w-auto max-w-2xl text-[20px] maxsm:text-[16px] font-[400] mt-5 before:content-[''] before:block before:w-[15px] before:height-full before:bg-white before:rounded-full after:content-[''] after:block after:absolute after:left-[-50px] after:top-[-20px] after:w-[500px] after:h-[500px] after:bg-[url('dots.png')] after:bg-no-repeat after:z-[-1] after:opacity-[0.4]"
      >
        Наша компания занимается поставкой дизельного топлива по всей территории Республики Узбекистан. Мы имеем
        многолетний опыт в этой области и готовы предложить нашим клиентам качественное топливо по приемлемой цене.
      </div>
    </div>
</template>


<script>
import Buttons from "@/components/UI/Buttons.vue"

export default {
  components: {
    Buttons
  }
}
</script>

<style lang="scss">

</style>