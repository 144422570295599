<template>
  <div class="product-list__item w-full p-[30px] maxsm:p-[10px]">
    <img
        class="block w-full h-[300px] object-cover rounded-tr-[4px] rounded-tl-[4px] border border-[#E5E8ED]"
        :src="getServerDomain + 'storage/' + item.pictures[0]"
        :alt="item.title"
    >
    <p class="font-[500] text-[18px] mt-[20px]">{{item.title}}</p>
    <div class="flex items-center gap-2 text-[#787A80]">
      <p>Цена:</p>
      <p>{{ item.price }}</p>
    </div>
    <a
        :href="getServerDomain + 'storage/' + item.certificate[0].download_link"
        class="block text-center mt-3 p-1 py-3 rounded-[4px] justify-center mt-auto p-1 w-full border-2 border-blue hover:bg-blue text-white text-[16px] text-blue hover:text-white font-medium duration-300 ease-in-out"
        :download="getServerDomain + 'storage/' + item.certificate[0].download_link"
    >
      Сертификат
    </a>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ProductListItem",
  props: {
    item: Object
  },
  computed :{
    ...mapGetters(['getServerDomain'])
  }
}
</script>

<style>
.product-list__item {
  border: 1px solid #E5E8ED;
  box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08),
  0px 30px 24px -10px rgba(154, 156, 165, 0.05),
  0px 12px 10px -6px rgba(154, 156, 165, 0.04),
  0px 4px 4px -4px rgba(30, 33, 44, 0.03);
  border-radius: 4px;
}
</style>