import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NewsList from "@/components/news/NewsList.vue";
import NewsPage from "@/views/NewsPage.vue";
import ProductList from "@/components/Products/ProductList.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/news-list',
    name: 'news-list',
    component: NewsList
  },
  {
    path: '/news-page/:id',
    name: 'news-page',
    component: NewsPage
  },
  {
    path: '/products-list',
    name: 'products-list',
    component: ProductList
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
