<template>
  <div class="w-full p-[30px] border border-[#E5E8ED] rounded-[4px]">
    <img
        class="w-full h-[600px]"
        :src="getServerDomain + 'storage/' + item" alt="">
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewsPicture",
  props: {
    item: Object
  },
  computed: {
    ...mapGetters(['getServerDomain'])
  }
}
</script>

<style scoped>

</style>