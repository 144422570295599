<template>
  <div id="products" class="px-[20px]">
    <div class="mb-[50px]">
      <h2 class="text-4xl text-dark font-bold">Наши продукты</h2>
      <div class="h-[2px] bg-[#86a8ff] rounded-[4px] w-full mt-[15px]"></div>
    </div>

    <ProductCategory
        v-for="item in this.getProducts"
        :item="item"
        :key="item.id"
    />

    <div class="flex items-center justify-center gap-4 mt-[30px] max9:flex-col max9:text-center">
      <p class="text-[28px] font-[700]">Хотите больше продуктов?</p>
      <router-link :to="{name:'products-list'}"
                   class="block text-center max-w-[300px] maxsm:text-[15px] maxsm:max-w-[150px] w-full text-white text-[20px] bg-blue py-5 rounded-[4px] font-bold cursor-pointer hover:bg-[#6488fe] shadow-sm duration-300 ease-in-out">
        Откройте
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ProductCategory from "@/components/Products/ProductCategory.vue";
export default {
  name: "Products",
  components: {ProductCategory},
  data() {
    return {
      isActive: false,
      params: {
        page: 1,
        limit: 6,
      }
    }
  },
  computed: {
    ...mapGetters(['getProducts'])
  },
  methods: {
    ...mapActions(['loadProducts']),
  },
  mounted() {
    let par = {page: this.params.page, limit: this.params.limit}
    this.loadProducts(par)
  }
}
</script>

<style scoped>

</style>