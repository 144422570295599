<template>
  <div>
    <button 
      :class="color"
      class="text-white font-sm my-7 rounded-lg flex justify-between items-center cursor-pointer"
    >
      <slot></slot>
    </button>
  </div>
</template>

<script>
  export default {
    props:['color']
  }
</script>

<style lang="scss" >

</style>