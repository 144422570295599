<template>
  <div
      class="flex lg:flex-col md:flex md:flex-col maxusm:flex-col maxmd:flex-col lg:pl-10 md:pl-0 lg:my-32 md:my-32 justify-center items-center usm:pb-0 md:pb-[100px] maxmd:pt-[60px] p-4"
      id="about">
    <div class="flex maxmd:flex-col about-column gap-10 lg:flex md:flex lg:flex-row md:flex-col lg:w-full lg:justify-between items-center">
      <aos-vue class="about-block max-w-lg  md:m-auto mx-auto " animation="fade-right" :offset="-100"
               :once="true" :duration="700"
      >
        <div class="relative bg-white/[.89] shadow-md border-b border-[#F0F3FD] p-3 before:content-[''] before:absolute before:block before:top-[-50px] before:left-[-50px] before:w-[180px] before:h-[180px] before:bg-[url('circle.png')] before:bg-cover before:z-[-1]">
          <titles><p>О компании</p></titles>
          <h2 class="text-3xl text-darkBlue ">Дизельный поток для вашего успеха</h2>
          <p class="pt-4 text-gray">Наша компания специализируется на поставке качественного дизельного топлива для
            различных отраслей промышленности и частных потребителей по всей территории Республики Узбекистан.</p>
<!--          <div class="about-line w-full mt-14"></div>-->
        </div>
      </aos-vue>
      <aos-vue class="about-block max-h-max md:m-auto usm-m-auto md:justify-center md:flex maxusm:flex"
               animation="fade-left"
               :offset="-50"
               :duration="800"
               :once="true"
      >
        <img class="md:w-9/12 lg:w-[700px] rounded-xl" src="@/../public/img/truck.jpg" alt="">
      </aos-vue>
    </div>
    <div
        class="about-column gap-10 maxmd:flex md:flex lg:flex-row lg:w-full md:flex-col-reverse maxmd:flex-col-reverse lg:justify-between items-center my-24">
      <aos-vue class="about-block md:m-auto usm-m-auto md:justify-center md:flex maxusm:flex"
               animation="fade-right"
               :offset="-50"
               :duration="700"
               :once="true"
      >
        <img class="rounded-lg md:w-9/12 lg:max-w-[700px] lg:w-full rounded-xl " src="@/../public/img/disel.jpg" alt="">
      </aos-vue>
      <aos-vue class="about-block lg:w-11/12 max-w-lg md:m-auto usm-m-auto"
               animation="fade-left"
               :offset="-100"
               :duration="800"
               :once="true"
      >
        <div class="relative bg-white/[.89] shadow-md border-b border-[#F0F3FD] p-3 before:content-[''] before:absolute before:block before:top-[-50px] before:right-[-50px] before:w-[180px] before:h-[180px] before:bg-[url('circle.png')] before:bg-cover before:z-[-1]">
          <titles><p>О компании</p></titles>
          <h2 class="text-3xl text-darkBlue ">Надежность и качество в каждой капле дизеля</h2>
          <p class="pt-4 text-gray">Мы имеем многолетний опыт работы на рынке и за это время завоевали доверие многих
            наших клиентов благодаря высокому качеству поставляемого топлива и своевременным поставкам.

            Мы сотрудничаем с надежными производителями и поставщиками дизельного топлива, которые соответствуют всем
            необходимым стандартам качества. Это позволяет нам гарантировать нашим клиентам поставки дизеля, которые
            соответствуют их требованиям и нормам качества.</p>
<!--          <div class="about-line w-full mt-14"></div>-->
        </div>
      </aos-vue>
    </div>
  </div>
</template>

<script>
import Titles from "@/components/UI/Titles.vue"
import Buttons from "@/components/UI/Buttons.vue"

export default {
  components: {
    Titles,
    Buttons
  }
}
</script>

<style lang="scss" scoped>
.about-line {
  background: #F0F3FD;
  height: 2px;
}
</style>