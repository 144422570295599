<template>
  <div>
    <h1 class="text-[32px] font-[900] mb-[30px]">{{ item.title }}</h1>

    <swiper
        :slides-per-view="3"
        :space-between="50"
        :pagination="{
            dynamicBullets: true,
        }"
        navigation
        :loop="false"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :modules="modules"
        :breakpoints="{
      1300: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      500: {
        slidesPerView: 1
      },
      300: {
        slidesPerView: 1
      }
      }"
    >
      <swiper-slide
          v-for="product in item.products"
          :key="product.id"
      >
        <product-item
            :item="product"
        />
      </swiper-slide>
      <swiper-slide>
        <router-link :to="{name:'products-list'}"
                     class="flex news_item hover:shadow-2xl w-full h-full items-center justify-center flex-col m-auto duration-300 ease-in-out">
          <img class="w-[200px] h-[200px] mt-auto" src="/img/products.svg" alt="news">
          <p class="text-[20px] font-[700] mt-[30px]">Открыть каталог</p>
          <a class="block text-center mt-[30px] m-auto max-w-[300px] maxsm:text-[15px] maxsm:max-w-[150px] w-full text-white text-[20px] bg-blue py-5 rounded-[4px] font-bold cursor-pointer hover:bg-[#6488fe] shadow-sm duration-300 ease-in-out">
            Все товары
          </a>
        </router-link>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Pagination, Navigation} from 'swiper'
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import ProductItem from "@/components/Products/ProductItem.vue";
export default {
  name: "ProductCategory",
  components: {ProductItem, Swiper, SwiperSlide},
  url: import.meta.url,
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      modules: [Pagination, Navigation],
      onSwiper,
      onSlideChange,
    };
  },
  props: {
    item: Object,
  }
}
</script>

<style scoped>

</style>