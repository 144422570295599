<template>
  <div id="news" class="px-[20px]">
    <div class="mb-[50px]">
      <h2 class="text-4xl text-dark font-bold">Новости</h2>
      <div class="h-[2px] bg-[#86a8ff] rounded-[4px] w-full mt-[15px]"></div>
    </div>
    <swiper
        :slides-per-view="3"
        :space-between="50"
        :pagination="{
            dynamicBullets: true,
        }"
        navigation
        :loop="false"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :modules="modules"
        :breakpoints="{
      1300: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      500: {
        slidesPerView: 1
      },
      300: {
        slidesPerView: 1
      }
      }"
    >
      <swiper-slide
          v-for="item in getNews.data"
          :key="item.id"
      >
        <news-item
            :item="item"
        />
      </swiper-slide>
      <swiper-slide>
        <router-link :to="{name:'news-list'}"
                     class="flex news_item hover:shadow-2xl w-full h-full items-center justify-center flex-col m-auto duration-300 ease-in-out">
          <img class="w-[200px] h-[200px] mt-auto" src="/img/news.svg" alt="news">
          <p class="text-[20px] font-[700] mt-[30px]">Читайте больше новостей</p>
          <a class="block text-center mt-[30px] m-auto max-w-[300px] maxsm:text-[15px] maxsm:max-w-[150px] w-full text-white text-[20px] bg-blue py-5 rounded-[4px] font-bold cursor-pointer hover:bg-[#6488fe] shadow-sm duration-300 ease-in-out">
            Все новости
          </a>
        </router-link>
      </swiper-slide>
    </swiper>

    <div class="flex items-center justify-center gap-4 mt-[30px] max9:flex-col max9:text-center">
      <p class="text-[28px] font-[700]">Хотите больше новостей?</p>
      <router-link :to="{name:'news-list'}"
                   class="block text-center max-w-[300px] maxsm:text-[15px] maxsm:max-w-[150px] w-full text-white text-[20px] bg-blue py-5 rounded-[4px] font-bold cursor-pointer hover:bg-[#6488fe] shadow-sm duration-300 ease-in-out">
        Откройте
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Pagination, Navigation} from 'swiper'
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import NewsItem from "@/components/news/NewsItem.vue";

export default {
  components: {Swiper, SwiperSlide, NewsItem},
  url: import.meta.url,
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      modules: [Pagination, Navigation],
      onSwiper,
      onSlideChange,
    };
  },
  data() {
    return {
      isActive: false,
      params: {
        page: 1,
        limit: 6,
      }
    }
  },
  computed: {
    ...mapGetters(['getNews'])
  },
  methods: {
    ...mapActions(['loadNews']),
  },
  mounted() {
    let par = {page: this.params.page, limit: this.params.limit}
    this.loadNews(par)
  }

}
</script>

<style lang="scss">
.swiper-wrapper {
  padding-bottom: 100px !important;
}

.swiper-slide {
  height: auto;
}

.swiper-button-next, .swiper-button-prev {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 100%;
  border: 1px solid #325ade;
  transition: 0.3s ease-in-out;
}

.swiper-button-next:after, .swiper-button-prev:after {
  width: 24px;
  height: 24px;
  font-size: 24px;
  text-align: center;
}

.swiper-button-next:hover:after, .swiper-button-prev:hover:after{
  color: #FFFFFF;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
  background-color: #325ade;
}

.news-title {
  p {
    position: relative;
    width: 100px;
    margin: 0 auto;
  }

  .yellow-line {
    content: '';
    position: absolute;
    text-align: center;
    width: 32px;
    left: 35px;
    height: 1px;
    font-size: 1px;
    background: #F59E0B;
  }

  .news-img {
    width: 200px;
    height: 144px;
  }
}
</style>