<template>
  <div class="news_item block hover:shadow-2xl w-full h-full border border-[#E5E8ED] rounded-[4px] duration-300 ease-in-out">
    <router-link :to="{name:'news-page', params:{id:item.slug}}">
      <img
          class="block w-full h-[400px] object-cover rounded-tr-[4px] rounded-tl-[4px]"
          :src="getServerDomain + 'storage/' + item.main_image"
          :alt="item.title"
      >
    </router-link>
    <div class="px-3 py-5 h-full">
      <div class="flex items-center gap-1 mb-2">
        <img src="/img/date.svg" alt="date">
        <p class="font-[400] text-[#787A80]">
          {{ formatTime(item.created_at) }}
        </p>
      </div>
      <p class="text-[#1E212C] font-[700] text-[20px] mb-[8px]">{{ formatTitle(item.title) }}</p>
      <p class="break-all">{{ formatDescription(item.description) }}</p>
      <router-link :to="{name: 'news-page', params:{id:item.slug}}" class="flex items-center mt-3 gap-1 py-3 rounded-[4px] justify-center mt-auto p-1 w-full border-2 border-blue hover:bg-blue text-white text-[16px] text-blue hover:text-white font-medium duration-300 ease-in-out">
        <p>Читать далее</p>
        <img src="img/arrow.svg">
      </router-link>
<!--      <p v-html="item.content"></p>-->
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewsItem",
  props:{
    item: Object
  },
  computed: {
    ...mapGetters(['getServerDomain'])
  },
  methods: {
    formatTime(data){
      let full_data = data;
      let full_data_time = new Date(full_data)
      let options = {year:"numeric", month:'short', day:'numeric', hour:'numeric', minute:'numeric'}

      return full_data_time.toLocaleString('ru', options)
    },
    formatTitle(data){
      let text = '';
      if(data.length > 30){
        text = data.slice(0, 29) + '...'
        return text
      } else {
        return data
      }
    },
    formatDescription(data){
      let text = '';
      if(data.length > 60){
        text = data.slice(0, 59) + '...'
        return text
      } else {
        return data
      }
    },
  }
}
</script>

<style>
.news_item {
  border: 1px solid #E5E8ED;
  box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08),
  0px 30px 24px -10px rgba(154, 156, 165, 0.05),
  0px 12px 10px -6px rgba(154, 156, 165, 0.04),
  0px 4px 4px -4px rgba(30, 33, 44, 0.03);
  border-radius: 4px;
}
</style>