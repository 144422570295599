<template>
  <div>
    <p
      :class="text"
      class="titles-text text-blue"
    >
    <slot></slot>
    </p>
  </div>
</template>

<script>
  export default {
    props: ['text']
  }
</script>

<style lang="scss" >
  .titles-text {
    position: relative;
    padding-left: 40px;
    &::before {
      content: '';
      position: absolute;
      width: 32px;
      height: 1px;
      top: 12px;
      left: 0px;
      font-size: 20px;
      background: #325ade;
    }
  }
</style>