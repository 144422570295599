<template>
  <div class="body font-main">
    <header class="header">
      <navbar></navbar>
     <div class="header-opacity container m-auto w-full container md:m-aut ">
        <div class="header-nav">
        </div>
        <div class="!pb-[200px] !pr-[30px] !pl-[70px] max-w-[568px]:pl-[30px]">
          <header-info></header-info>
            <a href="#help" class="block text-center mt-[30px] max-w-[300px] maxsm:text-[15px] maxsm:max-w-[150px] w-full text-white text-[20px] bg-blue py-5 rounded-[4px] font-bold cursor-pointer hover:bg-[#6488fe] shadow-sm duration-300 ease-in-out">
              Связаться с нами
            </a>
        </div>
      </div>
    </header>
    <section class="about relative -z-10">
      <div class="about-info container m-auto">
        <about-info></about-info>
      </div>
    </section>
    <section class="news">
      <div class="news-info container  m-auto">
        <news></news>
      </div>
    </section>
    <section class="our-strategy bg-strategy">
      <div class="our-strategy container  m-auto">
        <our-strategy></our-strategy>
      </div>
    </section>
    <section class="news">
      <div class="news-info container  m-auto">
        <products></products>
      </div>
    </section>
    <section class="form bg-strategy">
      <div class="form-info container  m-auto">
        <forms></forms>
      </div>
    </section>
    <footer class="footer">
      <div class="footer-info container  m-auto">
        <vault></vault>
      </div>
    </footer>
  </div>
</template>

<script>
import Navbar from '@/components/UI/Navbar.vue'
import HeaderInfo from '@/components/header/HeaderInfo.vue'
import AboutInfo from '@/components/about/AboutInfo.vue'
import OurStrategy from '@/components/strategy/OurStrategy.vue'
import Projects from '@/components/projects/Projects.vue'
import News from '@/components/news/News.vue'
import Forms from '@/components/form/Form.vue'
import Vault from '@/components/footer/Vault.vue'
import Slider from '@/components/UI/Slider.vue'
import {mapActions, mapGetters} from "vuex";
import Products from "@/components/Products/Products.vue";

export default {
  name: 'HomeView',
  components: {
    Products,
    Navbar,
    HeaderInfo,
    AboutInfo,
    OurStrategy,
    Projects,
    News,
    Vault,
    Forms,
    
    Slider
  },
  data(){
    return {
      params: {
        page: 1,
        limit: 6
      }
    }
  },
  computed: {
    ...mapGetters(['getNews', 'getProducts'])
  },
  methods: {
    ...mapActions(['loadNews', 'loadProducts'])
  },
  mounted() {
    let par = {page: this.params.page, limit: this.params.limit}
    this.loadNews(par)
    this.loadProducts(par)
  }
}
</script>

<style lang="scss" scoped>
   .header {
     min-height: 100vh;
    //background:
    //    linear-gradient(90deg, rgba(0, 69, 131, 0.7) 3.49%, rgba(0, 69, 131, 0.7) 40.86%, rgba(0, 0, 0, 0) 72.47%),
    //    url('@/../public/img/main.jpeg') no-repeat center center / cover;
     background:
         linear-gradient(90deg, rgba(73, 78, 83, 0.7) 3.49%, rgba(82, 90, 97, 0.7) 40.86%, rgba(0, 0, 0, 0) 72.47%),
         url('@/../public/img/main.jpeg') no-repeat center center / cover;
    filter: brightness(100%);

  }

   @media (max-height:580px) {
     .header {
       height: auto;
     }
   }

</style>