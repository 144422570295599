<template>
  <div class="body font-main">
    <header class="relative header bg-cover bg-[url('products.png')] before:content-[''] before:absolute before:top-[50px] before:left-[-80px] before:block before:w-[145px] before:h-[135px] before:bg-[url('dots-2.svg')] before:bg-cover before:rotate-[0deg] after:content-['']">
      <aos-vue class="navbar maxmd:relative lg:py-10 lg:flex lg:px-20 md:flex  justify-between items-center p-4 container md:mx-auto md:w-full maxmd:max-w-full "
               animation="slide-down"
               :duration="800"
               :once="true"
      >
        <router-link class="logo-block p-5 relative z-50 flex items-center cursor-pointer w-[150px] h-[150px] maxsm:w-[100px] maxsm:h-[100px] hover:w-[155px] hover:[155px] maxsm:hover:w-[105px] maxsm:hover:[105px] rounded-full overflow-hidden shadow-2xl duration-300 ease-in-out"
                     :to="{name: 'home'}"
        >
          <img v-if="!open" class="w-[120px] h-full m-auto" src="@/../public/img/fuel-energy-black.svg" alt="icon">
          <img v-if="open" class="w-[120px] h-full m-auto" src="@/../public/img/fuel-energy.svg" alt="icon">
        </router-link>
        <div
            @click="open = !open"
            class="block w-5 absolute right-6 top-1/2 transform -translate-y-1/2 md:hidden z-50 p-4"
        >
        <span
            aria-hidden="true"
            class="block absolute h-0.5 w-6 bg-blue transform transition duration-500 ease-in-out"
            :class="{ 'rotate-45': open, ' -translate-y-1.5': !open }"
        ></span>
          <span
              aria-hidden="true"
              class="block absolute h-0.5 w-6 bg-blue transform transition duration-500 ease-in-out"
              :class="{ 'opacity-0': open }"
          ></span>
          <span
              aria-hidden="true"
              class="block absolute h-0.5 w-6 bg-blue transform transition duration-500 ease-in-out"
              :class="{ '-rotate-45': open, ' translate-y-1.5': !open }"
          ></span>
        </div>
        <ul
            class="md:flex lg:items-center md:px-0 sm:justify-center maxmd:w-screen maxmd:fixed   md:justify-center lg:bg-inherit	maxmd:h-screen top-0 left-0 md:rounded-2xl overflow-y-hidden transition-all maxmd:bg-darkBlue duration-300 ease-in maxusm:pb"
            :class="{'maxmd:max-h-screen maxmd:pt-32 z-40':open, 'maxmd:max-h-0':!open}"
        >

          <li
              class="xl:mx-4 lg:mr-4 lg:my-6 md:mr-1 lg:flex md:flex sm:border-none border-2 maxmd:border-0 maxusm:py-2 md:border-borderTransparent text-center rounded-[32px] sm:py-1 maxmd:py-4"
          >
            <router-link :to="{name: 'home'}"
                         class="text-blue !text-[20px] !border-blue font-medium hover:bg-blue hover:text-white lg:bg-transparent md:bg-transparent lg:border-2 md:border-2 maxusm:border-0 text-sm sm:bg-none lg:border-transparent md:border-transparent rounded-[32px] max8:py-1 max8:px-2 min8:py-2 min8:px-4 xl:text-sm maxmd:text-3xl duration-300 ease-in-out"
                         :style="{ backgroundColor: activeColor }"
            >
              На главную
            </router-link>
          </li>
        </ul>
      </aos-vue>
      <div class="container m-auto pb-[300px]">
        <h3 class="font-[700] text-[16px] uppercase text-center">все наши продукты</h3>
        <h1 class="font-[900] text-[42px] text-center">Страница продуктов</h1>
      </div>
    </header>
    <div class="container m-auto py-[80px] flex items-start gap-5 flex-col px-[30px]">
      <div
          v-for="item in getProducts"
          :key="item.id"
          class="w-full"
      >
        <h1 class="font-[700] text-[16px] uppercase mb-[30px]"> {{ item.title }} </h1>
        <div class="grid gap-5 grid-cols-3 maxlg:grid-cols-2 maxsm:grid-cols-1">
          <product-list-item
              v-for="product in item.products"
              :item="product"
          />
        </div>
      </div>

      <!--    pagination-->
      <div class="flex items-center justify-end w-full mt-5">
        <div class="flex">
          <paginate
              v-model="params.page"
              @click="loadPage(params.page)"
              :next-text="'>'"
              :prev-text="'<'"
              :page-range="1"
              :margin-pages="1"
              :page-count="pageCount()"
              :container-class="'flex items-center'"
              :page-class="'flex items-center justify-center text-[20px] mx-2 font-gilroy rounded border cursor-pointer border-a-border text-a-border w-[34px] bg-white border-blue h-[34px] hover:bg-blue hover:text-white duration-300 easy-in-out'"
          />
        </div>
      </div>
      <!--    end pagination-->
    </div>
    <footer class="footer">
      <div class="footer-info container  m-auto">
        <vault></vault>
      </div>
    </footer>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";
import {mapActions, mapGetters} from "vuex";
import vault from "@/components/footer/Vault.vue";
import ProductListItem from "@/components/Products/ProductListItem.vue";
export default {
  name: "ProductList",
  components: {
    ProductListItem,
    vault,
    Paginate,
  },
  data(){
    return {
      params: {
        page: 1,
        limit: 10,
      },
      props: ["maxScroll"],
      scrollPosition: null,
      open: false,
    }
  },
  computed:{
    ...mapGetters(['getProducts'])
  },
  methods:{
    ...mapActions(['loadProducts']),
    pageCount(){
      return Math.ceil(this.getProducts.count / this.params.limit);
    },
    loadPage(page){
      this.loadProducts({limit:this.params.limit, page:page})
    },
  },
  mounted(){
    this.loadProducts(this.params)
  }
}
</script>

<style scoped>

</style>