import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import AosVue from 'aos-vue';
import './assets/tailwind.css';
import axios from "axios";
import VueAxios from "vue-axios";
import Paginate from "vuejs-paginate-next";
import VueTheMask from 'vue-the-mask'
import 'vue3-toastify/dist/index.css';
const app = createApp(App);
app.use(VueTheMask)
app.use(store);
app.use(router);
app.use(VueAxios, axios)
app.use(AosVue)
app.component("Paginate", Paginate);
app.mount("#app");
