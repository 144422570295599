<template>
  <div class="news_item block w-full border border-[#E5E8ED] rounded-[4px] duration-300 ease-in-out">
    <swiper
        :slides-per-view="1"
        :autoplay="{delay: 1000}"
        :loop="true"
        :space-between="10"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :modules="modules"
    >
      <swiper-slide
          v-for="(picture, index) in item.pictures"
          :key="index"
      >
        <img
            class="block w-full h-[400px] object-cover rounded-tr-[4px] rounded-tl-[4px]"
            :src="getServerDomain + 'storage/' + picture"
            :alt="item.title"
        >
      </swiper-slide>
    </swiper>
    <div class="px-3 py-5 h-full">
      <p class="text-[#1E212C] font-[700] text-[18px] mb-[8px]">{{ item.title }}</p>
      <div class="flex items-center gap-2">
        <p class="text-[#787A80]">Цена:</p>
        <p>{{ item.price }}</p>
      </div>
      <a
          :href="getServerDomain + 'storage/' + item.certificate[0].download_link"
          class="block text-center mt-3 p-1 py-3 rounded-[4px] justify-center mt-auto p-1 w-full border-2 border-blue hover:bg-blue text-white text-[16px] text-blue hover:text-white font-medium duration-300 ease-in-out"
          :download="getServerDomain + 'storage/' + item.certificate[0].download_link"
      >
        Сертификат
      </a>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Pagination, Navigation, Autoplay} from 'swiper'
import {mapActions, mapGetters} from "vuex";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';

export default {
  name: "ProductItem",
  components: {Swiper, SwiperSlide},
  props:{
    item: Object
  },
  url: import.meta.url,
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      modules: [Pagination, Navigation, Autoplay],
      onSwiper,
      onSlideChange,
    };
  },
  computed: {
    ...mapGetters(['getServerDomain'])
  },
  methods: {
    formatTime(data){
      let full_data = data;
      let full_data_time = new Date(full_data)
      let options = {year:"numeric", month:'short', day:'numeric', hour:'numeric', minute:'numeric'}

      return full_data_time.toLocaleString('ru', options)
    },
    formatTitle(data){
      let text = '';
      if(data.length > 30){
        text = data.slice(0, 29) + '...'
        return text
      } else {
        return data
      }
    },
    formatDescription(data){
      let text = '';
      if(data.length > 60){
        text = data.slice(0, 59) + '...'
        return text
      } else {
        return data
      }
    },
  }
}
</script>

<style scoped>

</style>