<template>
  <div class="body font-main">
    <header class="relative header bg-cover bg-[url('news-page.png')] before:content-[''] before:absolute before:top-[50px] before:left-[-80px] before:block before:w-[145px] before:h-[135px] before:bg-[url('dots-2.svg')] before:bg-cover before:rotate-[0deg] after:content-['']">
      <aos-vue class="navbar maxmd:relative lg:py-10 lg:flex lg:px-20 md:flex  justify-between items-center p-4 container md:mx-auto md:w-full maxmd:max-w-full "
               animation="slide-down"
               :duration="800"
               :once="true"
      >
        <router-link class="logo-block p-5 relative z-50 flex items-center cursor-pointer w-[150px] h-[150px] maxsm:w-[100px] maxsm:h-[100px] hover:w-[155px] hover:[155px] maxsm:hover:w-[105px] maxsm:hover:[105px] rounded-full overflow-hidden shadow-2xl duration-300 ease-in-out"
                     :to="{name: 'home'}"
        >
          <img v-if="!open" class="w-[120px] h-full m-auto" src="@/../public/img/fuel-energy-black.svg" alt="icon">
          <img v-if="open" class="w-[120px] h-full m-auto" src="@/../public/img/fuel-energy.svg" alt="icon">
        </router-link>
        <div
            @click="open = !open"
            class="block w-5 absolute right-6 top-1/2 transform -translate-y-1/2 md:hidden z-50 p-4"
        >
        <span
            aria-hidden="true"
            class="block absolute h-0.5 w-6 bg-blue transform transition duration-500 ease-in-out"
            :class="{ 'rotate-45': open, ' -translate-y-1.5': !open }"
        ></span>
          <span
              aria-hidden="true"
              class="block absolute h-0.5 w-6 bg-blue transform transition duration-500 ease-in-out"
              :class="{ 'opacity-0': open }"
          ></span>
          <span
              aria-hidden="true"
              class="block absolute h-0.5 w-6 bg-blue transform transition duration-500 ease-in-out"
              :class="{ '-rotate-45': open, ' translate-y-1.5': !open }"
          ></span>
        </div>
        <ul
            class="md:flex lg:items-center md:px-0 sm:justify-center maxmd:w-screen maxmd:fixed   md:justify-center lg:bg-inherit	maxmd:h-screen top-0 left-0 md:rounded-2xl overflow-y-hidden transition-all maxmd:bg-darkBlue duration-300 ease-in maxusm:pb"
            :class="{'maxmd:max-h-screen maxmd:pt-32 z-40':open, 'maxmd:max-h-0':!open}"
        >

          <li
              class="xl:mx-4 lg:mr-4 lg:my-6 md:mr-1 lg:flex md:flex sm:border-none border-2 maxmd:border-0 maxusm:py-2 md:border-borderTransparent text-center rounded-[32px] sm:py-1 maxmd:py-4"
          >
            <router-link :to="{name: 'home'}"
                         class="text-blue !text-[20px] !border-blue font-medium hover:bg-blue hover:text-white lg:bg-transparent md:bg-transparent lg:border-2 md:border-2 maxusm:border-0 text-sm sm:bg-none lg:border-transparent md:border-transparent rounded-[32px] max8:py-1 max8:px-2 min8:py-2 min8:px-4 xl:text-sm maxmd:text-3xl duration-300 ease-in-out"
                         :style="{ backgroundColor: activeColor }"
            >
              На главную
            </router-link>
          </li>
          <li class="xl:mx-4 lg:mr-4 lg:my-6 md:mr-1 lg:flex md:flex sm:border-none border-2 maxmd:border-0 maxusm:py-2 md:border-borderTransparent text-center rounded-[32px] sm:py-1 maxmd:py-4">
            <router-link :to="{name: 'news-list'}"
                         class="text-blue !text-[20px] !border-blue font-medium hover:bg-blue hover:text-white lg:bg-transparent md:bg-transparent lg:border-2 md:border-2 maxusm:border-0 text-sm sm:bg-none lg:border-transparent md:border-transparent rounded-[32px] max8:py-1 max8:px-2 min8:py-2 min8:px-4 xl:text-sm maxmd:text-3xl duration-300 ease-in-out"
                         :style="{ backgroundColor: activeColor }"
            >
              Новости
            </router-link>
          </li>
        </ul>
      </aos-vue>
      <div class="container m-auto pb-[300px]">
        <h1 class="font-[900] text-[42px] text-center">Страница новости</h1>
      </div>
    </header>
    <div class="container m-auto py-[80px] flex items-start gap-5 flex-col w-full px-[20px]">
      <h1 class="font-[900] text-[46px]">{{ getNewsDetail.title }}</h1>
      <p class="text-[#787A80] flex items-center gap-2">
        <img src="/img/date.svg" alt="date">
        {{ formatTime(getNewsDetail.created_at) }}
      </p>
      <img
          class="w-full h-[600px] object-cover"
          :src="getServerDomain + 'storage/' + getNewsDetail.main_image"
          alt="main img"
      >
      <p class="text-[20px] font-[700]">
        {{ getNewsDetail.description }}
      </p>
      <div class="w-full">
        <swiper
            :slides-per-view="1"
            :space-between="50"
            :pagination="{
            dynamicBullets: true,
            }"
            :loop="false"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :modules="modules"
        >
          <swiper-slide
              v-for="item in getNewsDetail.pictures"
              :key="item.id"
          >
            <news-picture :item="item"/>
          </swiper-slide>
        </swiper>
        <p
            v-html="getNewsDetail.content"
            class="text-[#424551] mt-[50px]"
        ></p>
      </div>
    </div>
    <footer class="footer">
      <div class="footer-info container  m-auto">
        <vault></vault>
      </div>
    </footer>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import NewsPicture from "@/components/news/NewsPicture.vue";
import vault from "@/components/footer/Vault.vue";

export default {
  name: "NewsPage",
  components: {NewsPicture, Swiper, SwiperSlide, vault},
  url: import.meta.url,
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      modules: [Pagination, Navigation],
      onSwiper,
      onSlideChange,
    };
  },
  data(){
    return {
      props: ["maxScroll"],
      scrollPosition: null,
      open: false,
    }
  },
  computed: {
    ...mapGetters(['getNewsDetail', 'getServerDomain'])
  },
  methods: {
    ...mapActions(['loadNewsDetail']),
    formatTime(date){
      let options = {month: 'long', day: 'numeric', year: 'numeric'}
      let d = new Date(date)
      return d.toLocaleString('ru', options)
    },
  },
  mounted() {
    console.log(this.$route.params.id)
    this.loadNewsDetail({slug:this.$route.params.id})
  }
}
</script>

<style scoped>

</style>