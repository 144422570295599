<template>
  <swiper
    class="swiper w-full maxlg:mt-10 "
    :loop="true"
    :slides-per-view="1"
    :pagination="{ clickable: true }"
    :autoplay="{ delay: 200, disableOnInteraction: false }"
    :breakpoints="{
      600: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1230: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1289: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    }"
  >
    <swiper-slide class="slide flex flew-wrap justify-between flex-col gap-5" >
      <div class="projects-block lg:w-[384px] sm:w-full md:w-auto w-full md:mx-auto   px-4">
        <img class="md:w-[334px] md:h-[247px] lg:w-[330px] sm:w-full rounded-lg" src="@/../public/img/elem-one.jpeg" alt="">
        <div class="projects-block__text text-white bg-blue md:w-[334px] sm:w-full  pb-[16px] pt-[12px] pr-[12px] pl-[12px] lg:w-[330px]">
          <h3 class="text-xl">Lorem ipsum dolor sit </h3>
          <p class="text-lg">2972 Westheimer Rd. Santa Ana, Illinois </p>
        </div>
      </div>
      <div class="projects-block lg:w-[384px] sm:w-full md:w-auto w-full md:mx-auto   px-4">
        <img class="md:w-[334px] md:h-[247px] lg:w-[330px] sm:w-full rounded-lg" src="@/../public/img/elem-one.jpeg" alt="">
        <div class="projects-block__text text-white bg-blue md:w-[334px] sm:w-full  pb-[16px] pt-[12px] pr-[12px] pl-[12px] lg:w-[330px]">
          <h3 class="text-xl">Lorem ipsum dolor sit </h3>
          <p class="text-lg">2972 Westheimer Rd. Santa Ana, Illinois </p>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide class="slide flex flew-wrap justify-between flex-col gap-5" >
      <div class="projects-block lg:w-[384px] sm:w-full md:w-auto w-full md:mx-auto   px-4">
        <img class="md:w-[334px] md:h-[247px] lg:w-[330px] sm:w-full rounded-lg" src="@/../public/img/elem-one.jpeg" alt="">
        <div class="projects-block__text text-white bg-blue md:w-[334px] sm:w-full  pb-[16px] pt-[12px] pr-[12px] pl-[12px] lg:w-[330px]">
          <h3 class="text-xl">Lorem ipsum dolor sit </h3>
          <p class="text-lg">2972 Westheimer Rd. Santa Ana, Illinois </p>
        </div>
      </div>
      <div class="projects-block lg:w-[384px] sm:w-full md:w-auto w-full md:mx-auto   px-4">
        <img class="md:w-[334px] md:h-[247px] lg:w-[330px] sm:w-full rounded-lg" src="@/../public/img/elem-one.jpeg" alt="">
        <div class="projects-block__text text-white bg-blue md:w-[334px] sm:w-full  pb-[16px] pt-[12px] pr-[12px] pl-[12px] lg:w-[330px]">
          <h3 class="text-xl">Lorem ipsum dolor sit </h3>
          <p class="text-lg">2972 Westheimer Rd. Santa Ana, Illinois </p>
        </div>
      </div>
    </swiper-slide>
    
   
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from 'swiper'
import "swiper/css";
import "swiper/css/pagination";

  export default({
    components: {  Swiper, SwiperSlide },
    name: 'swiper-example-pagination',
    title: 'Pagination',
    url: import.meta.url,
    setup() {
      return {
        modules: [Pagination]
      }
    }
  })
  
</script>

<style lang="scss" scoped>
 
</style>