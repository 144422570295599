<template>
  <aos-vue class="navbar maxmd:relative lg:py-10 lg:flex lg:px-20 md:flex  justify-between items-center p-4 container md:mx-auto md:w-full maxmd:max-w-full "
    animation="slide-down"
    :duration="800"
    :once="true"
  >
    <a class="logo-block p-5 relative z-50 flex items-center cursor-pointer w-[150px] h-[150px] maxsm:w-[100px] maxsm:h-[100px] hover:w-[155px] hover:[155px] maxsm:hover:w-[105px] maxsm:hover:[105px] rounded-full overflow-hidden shadow-2xl duration-300 ease-in-out"
      :href="'#'"
    >
      <img class="w-[120px] h-full m-auto" src="@/../public/img/fuel-energy.svg" alt="icon">
    </a>
    <div
        @click="open = !open"
        class="block w-5 absolute right-6 top-1/2 transform -translate-y-1/2 md:hidden z-50 p-4"
      >
        <span
          aria-hidden="true"
          class="block absolute h-0.5 w-6 bg-white transform transition duration-500 ease-in-out"
          :class="{ 'rotate-45': open, ' -translate-y-1.5': !open }"
        ></span>
        <span
          aria-hidden="true"
          class="block absolute h-0.5 w-6 bg-white transform transition duration-500 ease-in-out"
          :class="{ 'opacity-0': open }"
        ></span>
        <span
          aria-hidden="true"
          class="block absolute h-0.5 w-6 bg-white transform transition duration-500 ease-in-out"
          :class="{ '-rotate-45': open, ' translate-y-1.5': !open }"
        ></span>
    </div>
    <ul
      class="md:flex lg:items-center md:px-0 sm:justify-center maxmd:w-screen maxmd:fixed   md:justify-center lg:bg-inherit	maxmd:h-screen top-0 left-0 md:rounded-2xl overflow-y-hidden transition-all maxmd:bg-darkBlue duration-300 ease-in maxusm:pb"
      :class="{'maxmd:max-h-screen maxmd:pt-32 z-40':open, 'maxmd:max-h-0':!open}"
    >

      <li
        class="xl:mx-4 lg:mr-4 lg:my-6 md:mr-1 lg:flex md:flex sm:border-none border-2 maxmd:border-0 maxusm:py-2 md:border-borderTransparent text-center rounded-[32px] sm:py-1 maxmd:py-4"
        v-for="(link, index) in links"
      >
        <a
          :href="link.link"
          class="text-textWhite !text-[20px] font-medium hover:bg-white hover:text-blue lg:bg-transparent md:bg-transparent lg:border-2 md:border-2 maxusm:border-0 text-sm sm:bg-none lg:border-transparent md:border-transparent rounded-[32px] max8:py-1 max8:px-2 min8:py-2 min8:px-4 xl:text-sm maxmd:text-3xl duration-300 ease-in-out"
          :style="{ backgroundColor: activeColor }"
        >
          {{ link.name }}
        </a>
      </li>
    </ul>

  </aos-vue>
</template>

<script>
export default {
  data() {
    activeColor: "blue";
    return {
      props: ["maxScroll"],
      scrollPosition: null,
      open: false,
      links: [
        { name: "О компании", link: "#about" },
        { name: "Наша стратегия", link: "#strategy" },
        // { name: "Продукты", link: "#products" },
        // { name: "Новости", link: "#news" },
        { name: "Помощь", link: "#help" },
        { name: "Контакты", link: "#contact" },
      ],
    };
  },
};
</script>

<style lang="scss">

</style>
