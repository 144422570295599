<template>
  <div class="flex flex-col md:flex my-32 justify-center items-center pb-[100px] px-[30px]" id="help">
    <div class="form-section pt-[60px] maxusm:w-full">
      <aos-vue class="form-title text-center"
               animation="fade-down"
               :duration="700"
               :offset="-40"
               :once="true"
      >
        <h2 class="text-4xl text-dark font-bold">Чем мы можем помочь вам?</h2>
        <p class="text-xl text-dark w-6/12 pt-4 m-auto maxsm:w-full">
          В нашей компании мы всегда готовы выслушать ваши потребности и запросы, чтобы предложить наиболее эффективные
          и выгодные решения для вашего бизнеса. Наша команда профессионалов имеет богатый опыт в области поставки
          дизельного топлива, и мы знаем, как важно иметь надежного партнера, который готов поддержать вас в любых
          условиях.
        </p>
      </aos-vue>
    </div>
    <form @submit.prevent="createSupport" action="#" class="pt-[36px] maxmd:w-full">
      <aos-vue class="maxmd:w-full maxmd:items-center maxmd:flex maxmd:flex-col"
               animation="fade-down"
               :duration="650"
               :offset="-40"
               :once="true"
      >
        <div class="flex flex-col maxmd:items-center w-full">
            <input
                v-model="support.name"
                class="w-full h-10 pl-[8px] mb-[10px] border-2 border-neutral bg-white rounded-[4px] maxmd:mr-0 maxmd:my-5 focus:outline-0 focus:border-blue"
                type="text"
                placeholder="Ваше имя*"
                required
            >
            <input
                v-model="support.email"
                class="w-full h-10 pl-[8px] border-2 border-neutral bg-white rounded-[4px] focus:outline-0 focus:border-blue"
                type="email"
                placeholder="Email*"
                required
            >
            <input
                v-model="support.phone"
                v-mask="'+998(##) ###-##-##'"
                class="w-full mt-[10px] h-10 pl-[8px] border-2 border-neutral bg-white rounded-[4px] focus:outline-0 focus:border-blue"
                type="tel"
                placeholder="Телефон*"
                required
            >
            <textarea
                v-model="support.description"
                class="input-message w-full h-[135px] pl-[8px] border-[2px] border-neutral  bg-white rounded-[4px] focus:outline-0 focus:border-blue"
                placeholder="Сообщение"
                rows="10"
                col="40"
                required
            ></textarea>
        </div>
        <p class="text-gray px-4">* указывает на обязательное поле</p>
        <button
            type="submit"
            class="bg-blue hover:bg-[#6488fe] m-auto rounded-[8px] text-white flex items-center justify-center flex justify-center mt-7 cursor-pointer text-center text-lg max-w-[271px] w-full h-[54px] duration-300 ease-in-out">
          Отправить
        </button>
      </aos-vue>
    </form>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Buttons from '@/components/UI/Buttons.vue'

export default {
  components: {
    Buttons
  },
  data(){
    return {
      support: {
        name: '',
        phone: '+998',
        email: '',
        description: ''
      }
    }
  },
  methods: {
    ...mapActions(['sendSupport']),
    createSupport(){
      let order = {
        name: this.support.name,
        phone: this.support.phone,
        email: this.support.email,
        description: this.support.description
      }
      this.sendSupport(order);
      this.support.name = '';
      this.support.phone = '+998';
      this.support.email = '';
      this.support.description = '';
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  outline: none;

  &::placeholder {
    font-size: 18px;
  }
}

.input-message {
  margin-top: 19px;
  position: relative;
  resize: none;
  outline: none;

  &::placeholder {
    position: absolute;
    top: 0px;
  }
}

</style>