<template>
  <div
      id="contact"
      class="flex maxmd:flex-col maxsm:items-center md:flex-row md:justify-between  lg:xr-20  max-w-7xl  pb-5  justify-between maxmd:pb-0 px-4">
      <div class="logo-block sm:w-[320px] maxmd:m-auto text-center maxmd:flex maxmd:flex-col maxmd:justify-center maxmd:items-center"
      >
        <img src="@/../public/img/logo.svg" alt="">
        <p class="mt-[16px] opacity-[0.6]">Наша компания специализируется на поставке качественного дизельного топлива по Республике Узбекистан.
       </p>

      </div>
    <div class="footer-column sm:flex maxmd:flex maxmd:mt-[30px] maxmd:justify-center maxsm:flex-col"
    >
      <ul>
        <h2 class="text-darkBlue font-bold text-lg pr-[96px]">Меню</h2>
        <li class="mt-[25px]">
          <a href="#about" class="text-li-gray hover:text-blue duration-300 ease-in-out">О компании</a>
        </li>
        <li class="my-[19px] text-li-gray">
          <a href="#strategy" class="text-li-gray hover:text-blue duration-300 ease-in-out">Наша стратегия</a>
        </li>
        <li class="my-[19px] text-li-gray">
          <a href="#products" class="text-li-gray hover:text-blue duration-300 ease-in-out">Продукты</a>
        </li>
        <li class="my-[19px] text-li-gray">
          <a href="#news" class="text-li-gray hover:text-blue duration-300 ease-in-out">Новости</a>
        </li>
        <li class="my-[19px] text-li-gray">
          <a href="#help" class="text-li-gray hover:text-blue duration-300 ease-in-out">Помощь</a>
        </li>
      </ul>
      <ul>
        <h2 class="text-darkBlue font-bold text-lg">Контакты</h2>
        <li class="mt-[25px]">
          <a href="" class="text-li-gray hover:text-blue duration-300 ease-in-out">Ташкент, Узбекистан</a>
        </li>
        <li class="my-[19px]">
          <a href="tel:+998973331216" class="text-li-gray hover:text-blue duration-300 ease-in-out">+998 97 333 12 16</a>
        </li>
        <li class="my-[19px]">
          <a href="mailto:fuel.energy.uzb@gmail.com" class="text-li-gray hover:text-blue duration-300 ease-in-out">fuel.energy.uzb@gmail.com</a>
        </li>
      </ul>
    </div>
  </div>
  <p class="text-gray text-sm flex justify-center opacity-80 pb-3">Все права защищены {{ new Date().getFullYear() }}</p>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.rectangle-orange {
  width: 32px;
  height: 32px;
  background: #F59E0B;
  position: absolute;
  border-radius: 4px 4px 24px 4px;
}

.rectangle-blue {
  width: 32px;
  height: 32px;
  background: #4BE4C9;
  position: absolute;
  border-radius: 4px 4px 24px 4px;
}

li {
  cursor: pointer;
}
</style>